/* eslint-disable react/display-name */
import React from "react";

import { MDXProvider as MyMDXProvider } from "@parataxic/shared-ui";

import "@parataxic/shared-ui/src/styles/fonts.css";
import "./MDXProvider.css";

export const HSKMDXProvider = ({
  children,
  components = {},
  ...rest
}: React.ComponentProps<typeof MyMDXProvider>) => {
  return (
    <MyMDXProvider
      components={{
        ...components,
        code: ({ children, ...props }) => <code {...props}>{children}</code>,
      }}
      {...rest}
    >
      {children}
    </MyMDXProvider>
  );
};
