import React from "react";

import { MDXRenderer } from "gatsby-plugin-mdx";

import {
  GridWithSidebarStyling,
  SEO,
  SiteLink,
  ToCSidebar,
  useToC,
} from "@parataxic/shared-ui";

import { HSKMDXProvider } from "../components/MDXProvider";

import "@parataxic/shared-ui/src/styles/fonts.css";
import "./TableOfContents.css";

interface PageProps {
  data: { mdx: unknown };
}

const MDXPage: React.FC<PageProps> = ({ pageContext: { mdx } }) => {
  const title = mdx?.frontmatter?.title;
  const subtitle = mdx?.frontmatter?.subtitle;
  useToC({ querySelector: "#content nav div ul li a" });

  return (
    <div
      style={{ padding: "0 1rem", ...GridWithSidebarStyling }}
      id="content"
      role="article"
    >
      {title && <SEO title={`${title} - devel.tech`} />}
      <ToCSidebar
        mdx={mdx}
        variant="grid-with-sidebar"
        initialDepth={0}
        indent=".15rem"
      />
      <HSKMDXProvider
        style={{
          alignSelf: "start",
          gridColumn: 1,
          gridRow: 1,
          maxWidth: "936px",
          minWidth: 0,
        }}
      >
        <SiteLink
          href="/"
          style={{
            opacity: "0.4",
            marginTop: "-1rem",
            display: "block",
            _hover: { opacity: 1 },
          }}
        >
          {"<"} Back to frontpage
        </SiteLink>
        <h1>{title}</h1>
        {subtitle && (
          <>
            <hr style={{ color: "var(color-gray-300" }} />
            <h2 className="heading">{subtitle}</h2>
          </>
        )}
        {mdx?.body && <MDXRenderer>{mdx?.body}</MDXRenderer>}
      </HSKMDXProvider>
    </div>
  );
};

export default MDXPage;
